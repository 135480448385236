<template>
  <div>
    <employee-add></employee-add>
  </div>
</template>

<script>
import EmployeeAdd from './EmployeeAdd.vue';

export default {
  components: {
    EmployeeAdd,
  },

  data() {
    return {};
  },
};
</script>
